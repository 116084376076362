.tab {
  border: 1px solid #cccfd4;
  text-align: center;
  color: #9fa9ba;
  background-color: #fff;
  font-size: 14px;
  line-height: 19px;
  padding: 9px 0;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.disabled {
  cursor: not-allowed;
  background-color: rgba(220, 224, 230, 0.5);
}

.selected {
  background-color: rgba(24, 128, 231, 0.1);
  border: 1px solid #0077ff;
  color: #3276e8;
}

.tab:first-of-type {
  border-radius: 4px 0 0 4px;
}

.tab:last-of-type {
  border-radius: 0 4px 4px 0;
}

.tab:last-of-type:not(.selected) {
  border-left: 0;
}

.tab:first-of-type:not(.selected) {
  border-right: 0;
}
