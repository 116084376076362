.container {
  background-color: white;
}
.header {
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.text {
  cursor: pointer;
}

.header_label {
  font-family: 'Open Sans';
  font-size: 20px;
  color: #354052;
  margin-bottom: 10px;
  line-height: 36px;
}
.info_icon {
  color: rgba(99, 114, 128, 0.42);
  margin-left: 0.5em;
}
.pagination_container {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 10px;
}

.display_limit {
  display: block;
}

.page_size_select {
  display: block;
}

.headerHeadingContainer {
  height: 19px;
  width: 208px;
}

.headerHeadingText {
  composes: header;
  width: 200px;
}

.headerPendingViewersText {
  composes: header;
  width: 196px;
}

.headerUniqueViewers {
  composes: header;
  width: 111px;
}

.headerEngaged {
  composes: header;
  width: 63px;
}

.headerSnoozedContainer {
  height: 19px;
  width: 90px;
}

.headerSnoozedText {
  composes: header;
  width: 78px;
}

.headerAvgTimeSpentContainer {
  height: 19px;
  width: 180px;
}

.headerAvgTimeSpentText {
  composes: header;
  width: 167px;
}
